.feedSection {
  font-family: "Stopbuck";
  /* background-image: url('./img/blankmap.png'); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.feedelement {
  border: solid;
  border-width: 5px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: black(0, 255, 238);
  min-width: 20rem;
}

ul {
  padding: .5em;
  /* overflow-y: scroll; */
}




li {
  list-style-type: none;
  padding-bottom: 20px;
}



.userImage {
  image-rendering: pixelated;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border:solid;
  border-width: 3px;
  margin-bottom: .5em;
}
.wholeInput {
  border:solid;
  border-width: 5px;
  border-radius: 8px;
  padding: 10px;
  flex-direction: column;
}
.messageSection {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.headerSection {
  display: flex;
  text-align: left;
  align-content: flex-start; 
  align-items: flex-start;
  justify-content: left;
  font-size: 1.5em;
  padding: .5em;
  font-family: "Stopbuck";
}

textarea {
  width: 20rem;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 12px;
  resize: none;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  font-size: 12px;
  border: 3px solid black;
  display: inline-block;
  padding: .2em;
  margin: .5em;
  cursor: pointer;
  border-radius: 10px;
}

.post-button {
  border: 3px solid black;
  border-radius: 10px;
  font-family: "Stopbuck";
  font-size: 32px;
  color: black;
  padding: 3px 10px;
  background-color: rgb(124, 124, 207);
}
select {
  color: black;
}

.post-buttonSection {
  
  display: flex;
  justify-content: center;
}

.info {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 1em;
  padding-top: 1em;
  margin-bottom: auto;

}

.message {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
  justify-content: center;
  margin: .5em;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
}

.userImage {
}

.username {
  overflow: hidden;
}
.postimage {
  height: 10rem;
  max-width: 15rem;
  image-rendering: pixelated;
  margin-top: 1.5rem;
}

.message-text {
  padding: 1em;
  font-size: 14px;
}