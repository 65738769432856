.App {
  text-align: center;
}

.App-header {
  font-family: "Stopbuck";
  background-image: url('./img/blankmap.png');
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
 
}

.toptext {
  font-size: calc(60px + 2vmin);
  color: white;
}

.middletext {
  font-size: calc(20px + 2vmin);
  color: rgb(197, 197, 197);
}

.bottomtext {
  font-size: calc(5px + 2vmin);
  color: rgb(151, 151, 151);
}

.App-body {
  background-color: rgb(32.5, 39.2, 22.7);
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: white;
}

